@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Robotoを優先的に使用するように変更 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.student-icon, .teacher-icon, .point-icon {
  display: block; /* または inline-block */
  margin-bottom: 5px; /* アイコンとテキストの間隔 */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #f8f8f8;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
  padding: 0 16px;
}

/* ロゴコンテナを左端に配置 */
.header-logo-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 40px; /* ロゴのみにするため幅を制限 */
  overflow: hidden; /* タイトルがはみ出さないようにする */
}

/* ユーザーメッセージを中央に配置 */
.header-user-message {
  position: absolute; /* 絶対配置で中央に固定 */
  left: 50%;
  transform: translateX(-50%); /* 中央揃え */
  text-align: center;
  max-width: 50%;
  font-size: 18px;
  font-weight: 700;
  color: #333;
  padding: 6px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ロゴのスタイル */
.header-logo {
  height: 24px;
  position: static; /* 絶対配置を解除 */
}

/* ベースとなるスタイル（モバイル向け） */
.chat-container {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

/* モバイル用input-container */
.input-container-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 展開可能な入力エリアのスタイル */
.expandable-input-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.input-container-mobile .chat-input {
  font-size: 16px;  /* モバイルでの入力文字サイズ */
  padding-left: 20px;  /* 左側の余白を増やして文字を左寄せに */
  max-height: 120px; /* モバイルでは少し小さめに */
}

/* PC用input-container */
.input-container-pc {
  display: none; /* デフォルトでは非表示 */
  width: 100%;
  max-width: 700px;
  margin: 2rem auto 0;
  padding: 0 15px;
  box-sizing: border-box;
}

.input-container-pc .chat-input {
  font-size: 16px;  /* PC画面での入力文字サイズ */
  padding-left: 20px;  /* 左側の余白を増やして文字を左寄せに */
}

footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 15px;
  box-sizing: border-box;
}

/* レスポンスエリアのスタイル */
.response-area {
  margin-top: 60px; 
  margin-bottom: 20px; 
  background-color: #f8f8f8;
}

.user-message {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.user-segment {
  background-color: #FFE599;
  border-radius: 20px;
  padding: 8px 12px;
  line-height: 1.6;
  word-wrap: break-word;
}

/* 関連ワードとキーワードのスタイル */
.word-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.word-button {
  padding: 5px 12px;
  background-color: #E8DEFF;
  color: #424242; /* 濃いグレー */
  border: 1px solid #C5B3FF;
  border-radius: 15px;
  font-size: 14px;
}

.word-button:hover {
  background-color: #cce6ff;
}

/* 評価フォームのスタイル */
.evaluation-form {
  margin-top: 1rem;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.rating-comment-container {
  display: flex;
  align-items: left;
  justify-content: flex-start; /* Left align */
  gap: 0.1rem;
  width: 100%; /* 幅を最大に */
}

.comment-area {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.comment-icon-button {
  background: none;
  border: none;
  color: #6A47D9;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  padding: 0;
}

.comment-icon-button:hover {
  background-color: rgba(106, 71, 217, 0.1);
  transform: scale(1.05);
}

/* Hide the old comment button container */
.comment-button-container {
  display: none;
}

/* Review container adjust */
.review-container {
  width: 100%;
  max-width: 42rem;
  box-sizing: border-box;
  text-align: left;
  padding: 0.5rem 0;
}


/* アクションボタンのスタイル - マージンの代わりにpadding使用 */
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* centerからflex-startに変更 */
  gap: 0.75rem;
  padding: 0 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.action-button {
  padding: 0.5rem 0.5rem; /* 上下0.5rem、左右1rem (1:2の比率) */
  background-color: #ffffff; /* アクションボタンの色 */
  color: #424242; /* 濃いグレー */
  border: 2px solid #8b5dff; /* 指定の色でボーダーラインを追加 */
  border-radius: 999px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
  min-width: 100px;
  max-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 影を追加 */
}

.action-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-heading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.chat-layout {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

.chat-content {
  width: 100%;
  max-width: 42rem;
  margin: 0 auto;
  height: 100%; /* 高さを100%に設定 */
}

.chat-heading {
  font-size: 2.44rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.chat-initial-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px; /* input-containerの高さ分の余白を確保 */
}

.input-area {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8; /* 入力エリアの背景色を変更 */
  padding: 15px;
  z-index: 1000; /* 他の要素より前面に */
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box; /* これを追加 */
}

.submit-button:hover, .input-area button:hover {
  background-color: #0052a3;
}

.input-wrapper {
  width: 100%; /* 変更 */
  max-width: 1024px;
  margin: 0 auto; /* 中央寄せを維持 */
  box-sizing: border-box;
  position: relative; /* send-buttonの配置のために追加 */
  display: flex;
  align-items: flex-end;
  min-height: 54px; /* 最小高さを設定 */
}

/* テキストエリアのスタイル - 上方向拡張用 */
.chat-input {
  width: 100%;
  height: 54px; /* 初期高さを固定 */
  min-height: 54px; /* 最小高さを設定 */
  padding: 0.75rem 48px 0.75rem 20px; /* 右に送信ボタン用スペース */
  border: 1px solid #e5e5e5;
  border-radius: 1.5rem;
  font-size: 1rem;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  resize: none; /* リサイズハンドルを非表示 */
  overflow-y: hidden; /* 初期状態ではスクロールバーを非表示 */
  line-height: 1.5; /* 行の高さを設定 */
  max-height: 150px; /* 最大高さを制限 */
  position: relative; /* 位置指定のため */
  bottom: 0; /* 下端に配置 */
  align-self: flex-end; /* 親要素の下端に合わせる */
  padding-left: 20px;  /* 現在の左パディング */
}

.chat-input:focus {
  outline: none;
  border-color: #999;
}

.submit-button-inner {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem 1rem;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button-inner:hover {
  background-color: #0052a3;
}

body {
  font-family: 'Roboto', sans-serif; /* Robotoを優先的に使用するように変更 */
  background-color: #f8f8f8; /* 全体の背景色を#f8f8f8に設定 */
  margin: 0;
  padding: 0;
}

/* 送信ボタンのスタイル - 固定位置 */
.send-button {
  position: absolute;
  right: 8px;
  bottom: 11px; /* 下からの固定位置 */
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: none;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.2s, color 0.2s;
  z-index: 10; /* テキストエリアより前面に表示 */
}

.send-button-active {
  background-color: #8c52ff; /* または好みの色 */
  color: white;
}

.send-button-active:hover {
  background-color: #5e17eb; /* ホバー時は少し濃い色に */
  color: white;
}

.send-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

/* チャットメッセージのスタイル */
.chat-messages {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f8f8f8;
}

.chat-message-group {
  margin-bottom: 1.5rem;
}

.chat-message-set.no-gap {
  margin-top: -12px; /* chat-messagesのgapを相殺 */
  padding-top: 8;    /* 上部のパディングを削除 */
}


.message-container {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.message-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.chat-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  font-size: 16px;  /* 明示的に16pxを指定する場合 */
  font-weight: 400;  /* または必要な値（500, 600, 700など）*/
  flex: 1;
  line-height: 1.4;
  min-width: 0; /* テキストの折り返しを適切に処理するため */
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-segment {
  border-radius: 15px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;  
  line-height: 1.4;
  width: fit-content;     /* コンテンツに合わせて幅を調整 */
  max-width: 100%;        /* 親要素の幅を超えないように制限 */
  height: auto;           /* 高さを内容に合わせて自動調整 */
  word-wrap: break-word;  /* 長い単語も折り返す */
}

/* 先生のメッセージ用スタイル */
.teacher-segment {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5); /* 半透明の黒い枠線 */
}

/* 生徒のメッセージ用スタイル */
.student-segment {
  background-color: #6a47d9;
  color: white; /* 文字色を白に設定 */
}

/* アイコンの周りに余白を追加 */
.response-area img.chat-icon {
  margin: 0 5px;
  display: inline-block;
}

.expandable-input-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

/* App.css に追加するスタイル */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.loading-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-spinner div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #8c52ff;
  animation: loading-spinner 1.2s linear infinite;
}

.loading-spinner div:nth-child(1) {
  top: 32px;
  left: 8px;
  animation-delay: 0s;
}

.loading-spinner div:nth-child(2) {
  top: 32px;
  left: 32px;
  animation-delay: -0.4s;
}

.loading-spinner div:nth-child(3) {
  top: 32px;
  left: 56px;
  animation-delay: -0.8s;
}

/* post-animation-contentのスタイル更新 */
.post-animation-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px; /* 全ての子要素間の間隔を統一 */
}

/* 評価コンポーネント用スタイル */
.review-container {
  width: 100%;
  max-width: 42rem;
  box-sizing: border-box;
  text-align: left;
}

.review-title {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  text-align: left;
}

.stars-container {
  display: flex;
  justify-content: flex-start; /* Changed from center to flex-start */
  margin-bottom: 1rem;
  width: auto; /* 幅を内容に合わせる */
  margin-left: 0; /* 左マージンを0に */
}

.stars-span {
  display: flex;
  flex-direction: row-reverse;
}

.star-label {
  color: #D2D2D2;
  font-size: 30px;
  padding: 0 5px;
  cursor: pointer;
  transition: transform 0.2s;
}

.star-label:hover,
.stars-span:not(.disabled) .star-label:hover ~ .star-label {
  color: #6A47D9;
  transform: scale(1.1);
}

.star-input:checked ~ .star-label {
  color: #6A47D9;
}

.star-label.disabled {
  cursor: default;
}

.comment-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.comment-button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #4b5563;
  transition: color 0.3s;
  background: none;
  border: none;
}

.comment-button:hover {
  color: #1f2937;
}

.comment-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.comment-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  outline: none;
}

.comment-textarea:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #3b82f6;
  color: white;
  transition: background-color 0.3s;
  border: none;
}

.submit-button:hover:not(:disabled) {
  background-color: #2563eb;
}

.submit-button:disabled {
  background-color: #9ca3af;
  color: #e5e7eb;
  cursor: not-allowed;
}

.success-message {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: #d1fae5;
  color: #047857;
  border-radius: 0.375rem;
  text-align: center;
}

/* 星入力のラジオボタンを非表示 */
.star-input {
  display: none;
}

/* スキップボタンコンテナを右端に配置 */
.header-skip-button-container {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
}

/* ヘッダー内のスキップボタンスタイル */
.header-skip-button-container .skip-button {
  background-color: #8c52ff;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 2px 8px rgba(140, 82, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  letter-spacing: 0.5px;
  white-space: nowrap; /* テキストが折り返されないようにする */
  height: 30px; /* 高さを固定して位置を安定させる */
}

.header-skip-button-container .skip-button:hover {
  background-color: #7642e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(140, 82, 255, 0.5);
}

.header-skip-button-container .skip-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(140, 82, 255, 0.3);
}

/* 二重矢印のアイコン */
.header-skip-button-container .skip-button:before {
  content: "»";
  font-size: 18px;
  line-height: 0.8; /* 行の高さを下げて上に配置 */
  font-weight: bold;
  margin-right: 4px;
  position: relative;
  top: -1px; /* さらに上に微調整 */
  display: inline-block;
}

/* 初期表示のロゴとタイトル配置をスタイリッシュにする */
.logo-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden; /* アニメーションが範囲外に出ないように */
}

/* ロゴのスタイル改善 */
.chat-logo {
  height: 80px;
  margin-right: 16px;
  transform: translateY(-4px); /* 少し上に配置 */
  transition: transform 0.5s ease, filter 0.5s ease;
}

.logo-title-container:hover .chat-logo {
  transform: translateY(-8px) rotate(5deg);
  filter: drop-shadow(0 4px 12px rgba(140, 82, 255, 0.5));
}

/* タイトルのスタイル改善 */
.chat-title {
  display: flex;
  flex-direction: column;
  margin: 0;
  line-height: 0.9;
  position: relative;
}

.title-first-line, .title-second-line {
  display: block;
}

.title-first-line {
  font-size: 1.8rem;
  font-weight: 300;
  color: #666;
  margin-bottom: 0.2rem;
  letter-spacing: 0.5px;
  transform: translateX(-4px);
}

.title-second-line {
  font-size: 4rem;
  font-weight: 700;
  color: #6A47D9;
  letter-spacing: -1px; /* 文字間を少し詰める */
  background: linear-gradient(135deg, #8c52ff 0%, #5e17eb 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* 標準プロパティとしてのフォールバック */
  text-shadow: 0px 2px 4px rgba(140, 82, 255, 0.2);
}

/* サブタイトルのスタイル改善 */
.chat-subtitle {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.8px;
  position: relative;
  padding-bottom: 1rem;
}

.chat-subtitle:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, rgba(140, 82, 255, 0) 0%, rgba(140, 82, 255, 0.8) 50%, rgba(140, 82, 255, 0) 100%);
  border-radius: 3px;
}

/* CSSに追加するsr-onlyクラス */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* LoadingProgressBar用のスタイル - App.cssに追加 */
.loading-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 60px auto 20px;
  padding: 20px;
}

.loading-progress-label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.loading-progress-track {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.loading-progress-bar {
  height: 100%;
  background: linear-gradient(to right, #8c52ff, #6A47D9);
  border-radius: 4px;
  transition: width 0.3s ease;
  position: relative;
}

/* インターミネイトモード用のアニメーション */
.loading-progress-bar.indeterminate::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  animation: shimmer 1.5s infinite;
  transform: translateX(-100%);
}


/*
.input-wrapper.expanded {
  width: 100%;
  animation: slideIn 0.3s ease-out;
}

.typewriter-text {
  min-height: 1.6em;
  white-space: pre-wrap;
}

.typewriter-text::after {
  content: '|';
  animation: cursor-blink 0.1s infinite;
  opacity: 0;
}


/* モバイル用のスタイル調整 */
@media (max-width: 767px) {

  .header-user-message {
    font-size: 16px;
    font-weight: 700; /* モバイルでも太字に */
    max-width: 180px; /* モバイルでは幅をさらに制限 */
  }

  .chat-logo {
    height: 60px;
    margin-right: 12px;
  }
  
  .title-first-line {
    font-size: 1.4rem;
  }
  
  .title-second-line {
    font-size: 3rem;
  }
  
  .chat-subtitle {
    font-size: 1.2rem;
  }

  .header-skip-button-container {
    margin-right: 1px; /* スマホではマージンを小さく */
    padding-top: 4px; /* モバイルでは少し小さめの余白 */
  }
  
  .header-skip-button-container .skip-button {
    padding: 6px 10px;
    font-size: 12px;
    height: 32px; /* モバイルでは少し小さく */
  }
  
  .header-skip-button-container .skip-button:before {
    font-size: 16px; /* モバイルでは少し小さく */
    top: -1px;
  }

  .header-logo-container {
    justify-content: flex-start; /* 中央揃えから左揃えに変更 */
    padding-left: 0; /* 左のパディングを削除してさらに左に寄せる */
  }
  
  /* 必要に応じてロゴとタイトルのサイズも調整 */
  .header-logo {
    height: 32px;
  }
  
  .header-title {
    font-size: 18px;
  }

  .chat-container {
    padding-bottom: 120px; /* フッター入力エリアの高さ + 余白分を確保 */
  }

  .evaluation-form {
    margin-bottom: 80px; /* フッター入力エリアの高さ分の余白を確保 */
  }

  /* モバイル用フッター入力エリアの位置を固定 */
  .input-container-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent 0%, transparent 50%, #f8f8f8 50%, #f8f8f8 100%);
    z-index: 1001; /* expandable-inputより上に表示 */
  }

  /* コンテナの余白調整 */
  .chat-container {
    padding-bottom: 84px; /* 入力エリアの最小高さ + パディング */
  }

  .action-buttons {
    gap: 0.25rem;
    padding: 0 0.25rem;
  }
  
  .action-button {
    flex: 0 1 auto; /* 伸長しないが縮小は許可、コンテンツサイズに基づいて初期サイズを設定 */
    padding: 0.5rem 1.75rem; /* 上下0.5rem、左右1rem (1:2の比率) */
    font-size: 16px;
    font-weight: 800;
  }

  .comment-textarea {
    width: 100%;
    box-sizing: border-box; /* パディングとボーダーを幅に含める */
  }
  
  .stars-container {
    margin-bottom: 0.75rem; /* モバイルでの余白調整 */
  }
  
  .star-label {
    font-size: 24px; /* モバイルでは星を少し小さく */
    padding: 0 3px;
  }
  
  .comment-form {
    margin-top: 0.75rem;
    gap: 0.75rem;
  }
  
  .comment-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .submit-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .loading-progress-container {
    max-width: 90%;
    margin: 40px auto 15px;
    padding: 10px;
  }
  
  .loading-progress-track {
    height: 6px; /* モバイルでは少し細く */
  }
  
  .loading-progress-label {
    font-size: 12px; /* モバイルでは文字を小さく */
  }
}

/* タブレット向け (768px以上) */
@media (min-width: 768px) {
  .chat-logo {
    height: 70px;
  }
  
  .title-first-line {
    font-size: 1.6rem;
  }
  
  .title-second-line {
    font-size: 3.5rem;
  }

  .input-container-pc {
    display: block; /* タブレット表示でも表示 */
    background-color: #f8f8f8;
  }
  
  .input-container-mobile {
    display: none;
  }
  
  .chat-container {
    padding: 20px;
    max-width: 90%;
  .input-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
  }

  .chat-heading {
    font-size: 1.5rem;
  }

  .input-container, .input-area {
    flex-direction: row;
    gap: 1rem;
  }

  .submit-button, .input-area button {
    width: auto;
    min-width: 120px;
  }

  .action-buttons {
    flex-direction: row;
  }

  .input-container-mobile {
    display: none;
  }
}

/* PC向けのスタイル調整 */
@media (min-width: 1024px) {
  .input-container-pc {
    display: block; /* PC表示では表示 */
    background-color: #f8f8f8;
  }

  .chat-container {
    max-width: 800px;
    padding: 30px;
    margin: 0 auto;
  }

  .chat-layout {
    min-height: auto; /* 高さ制限を解除 */
    padding: 2rem 1.5rem;
    background-color: #f8f8f8; /* チャットレイアウトの背景色を変更 */
  }

  .chat-initial-content {
    padding-bottom: 0;
  }

  .chat-subtitle {
    font-size: 1.25rem;
    color: #000;
    margin-bottom: 1rem; /* サブタイトルの下マージンを調整 */
    text-align: center; /* テキストを左右中央に配置 */
    width: 100%; /* 幅を100%に設定して親要素いっぱいに広げる */
  }

  .input-wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .action-buttons {
    justify-content: flex-start;
    padding: 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .action-button {
    flex: 0 0 auto;
    min-width: 140px;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes loading-spinner {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.8);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-initial-content {
  animation: fadeInUp 0.8s ease-out;
}

.title-first-line {
  animation: fadeInUp 0.6s ease-out 0.2s both;
}

.title-second-line {
  animation: fadeInUp 0.6s ease-out 0.4s both;
}

.chat-subtitle {
  animation: fadeInUp 0.6s ease-out 0.6s both;
}

/* フェードインアニメーション */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 段階的なフェードイン用のクラス */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

/* ボタンのフェードインアニメーション */
.word-buttons .word-button {
  opacity: 0;
  animation: fadeIn 0.4s ease-out forwards;
}

/* セクション間の間隔を調整 */
.related-words-section,
.keywords-section,

/* アクションボタンなどのホバーエフェクト強化 */
.action-button:hover,
.word-button:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(140, 82, 255, 0.4);
  }
  70% {
    box-shadow:.0 0 0 10px rgba(140, 82, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(140, 82, 255, 0);
  }
}